.Footer {
  position: relative;
  &__contact {
    width: 100%;
    padding: 100px 0 150px 0;
    background: #0a0a0a; }
  &__copy {
    width: 100%;
    padding: 30px 0; } }

.contact__info-wrapper {
  text-align: center; }

.contact a {
  color: #898989;
  display: block; }

.contact {
  color: #898989;
  &__row {
    margin-top: 100px; }
  &__country {
    text-transform: uppercase;
    font-family: $f-medium;
    letter-spacing: 2px;
    font-size: 16px;
    margin-bottom: 40px; }
  &__location {
    display: block; }
  &__phone {
    margin: 20px 0;
    transition: opacity .4s $curva-lines-burger;
    &:hover {
      opacity: .4; } }
  &__email {
    transition: opacity .4s $curva-lines-burger;
    &:hover {
      opacity: .4; } }
  &__imgMail {
    width: 26px;
    margin-bottom: 17px;
    margin-top: 20px;
    opacity: .3; } }


.Footer__copy {
  color: #2f2f2f;
  font-size: 14px;
  &__social {}
  &__copy {
    text-align: center; }
  &__brand {
    display: none;
    text-align: right;
    img {
      opacity: .3; } } }


path {
  transition: fill .4s $curva-lines-burger;
  fill: #4e4e4e; }

.social-menu {
  &__item {
    display: inline-block;
    margin-right: 20px; }
  &__icon {
    height: 13px;
    &:hover path {
      fill: $c-blanco; } } }
