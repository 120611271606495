//Globales
.wrapper-principal-project {
  width: 100%;
  padding: 160px 0 85px 0;
  background: #3a3a3a;
  color: #d9dada;
  .texto-oscuro-bio {
    margin-top: 30px;
    color: #808080; } }

.e-proyecto {
  position: relative;
  padding: 120px 0; }

.last-section {
  position: relative;
  z-index: 5; }

.over-hide {
  overflow: hidden; }

.Projects {
  padding: 180px 0 170px 0; }


.wrapper-logo-brand {
  position: absolute;
  z-index: 5;
  width: 50%;
  top: 20%;
  right: 0;
  img.logo {
    position: relative;
    opacity: .2; } }

//Titulo Etapa
.wrapper-principal-etapa {
  margin-bottom: 90px;
  article.titulo-etapa {
    .text-titulo {
      font-size: 40px;
      display: block;
      line-height: 40px; }
    div.number-etapa {
      font-family: $f-light;
      word-spacing: -10px;
      div.show-number {
        position: relative;
        display: inline-block;
        visibility: inherit;
        opacity: 0;
        transform: matrix(1, 0, 0, 1, 0, -20);
        transition: transform .8s $curva-lines-burger, opacity 1.2s $curva-lines-burger;
        will-change: transform, opacity; } }
    h3.nombre-etapa {
      font-family: $f-bold;
      word-spacing: -9px;
      div.show-letter {
        position: relative;
        display: inline-block;
        visibility: inherit;
        opacity: 0;
        transform: matrix(1, 0, 0, 1, 0, 20);
        transition: transform .8s $curva-lines-burger, opacity 1.2s $curva-lines-burger;
        will-change: transform, opacity; } } }
  article.extracto-etapa {
    .texto-extracto {
      opacity: 0;
      visibility: hidden;
      transform: translate3d(-10px, 0, 0);
      transition: transform 1s $curva-lines-burger .2s, opacity 1s $curva-lines-burger .2s, visibility 1s $curva-lines-burger .2s; } } }
//Fin Titulo Etapa

//Grid Images Etapas
.wrapper-images-grid {
  padding: 0 15px;
  .image {
    padding: 0; } }

.wrapper-images-grid--margin {
  margin-bottom: 90px; }
//Fin Grid Images Etapas


section#principal-project {
  #datos-proyecto {
    margin-bottom: 125px;
    article.datos-cliente {
      h2.nombre-cliente {
        font-family: $f-bold;
        font-size: 45px;
        margin-bottom: 25px; }
      ul.lista-datos-cliente {
        li.item-dato-cliente {
          font-family: $f-light;
          font-size: 15px; } } }

    article.extracto-cliente {
      ul.lista-etapas {
        li.item-etapa {
          display: inline-block;
          margin-right: 5px;
          font-family: $f-light;
          font-size: 15px; } } } }

  #video-cliente {
    .video-wrap {
      padding: 56.25% 0 0;
      position: relative;
      height: 0;
      width: 100%;
      margin: 0 0 25px;
      iframe {
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        position: absolute; } } } }


section#etapa-branding {
  .wrapper-detalle-branding {
    article.detalle-branding {
      p.texto-detalle-branding {
        width: 30%;
        margin-bottom: 50px; }
      .punto-detalle {
        margin-bottom: 30px;
        span.detalle {
          display: block; }
        span.titulo-detalle {
          font-family: $f-light;
          font-size: 15px;
          margin-bottom: 15px; }
        span.tipo {
          font-size: 45px;
          font-family: $f-medium; }
        img.pattern {
          width: 85px;
          margin-right: 15px; }
        img.paleta {
          height: 75px; } } } } }


section#etapa-website {
  .e-website__images {
    &__image {
      margin-bottom: 90px; }
    &__image--width {
      width: 75%; }
    &__image:last-child {
      margin: 0; } } }


section#etapa-resultado {
  overflow: hidden;
  .wrapper-resultados-items {
    .resultado-item:nth-child(1) {
      margin-top: 90px;
      margin-right: 75px; }
    .resultado-item:nth-child(2) {
      margin-left: 75px; }
    .resultado-item {
      article.inner-items:nth-child(1) {
        margin-bottom: 65px; }
      article.inner-items {
        text-align: center;
        img {
          height: 60px;
          margin-bottom: 25px; }
        h3.titulo-item-resultado {
          font-size: 22px;
          font-family: $f-bold;
          text-transform: uppercase;
          margin-bottom: 18px;
          span {
            display: block; } }
        p.extracto-item-resultado {
          font-family: $f-light;
          font-size: 15px;
          color: #6f6f6f; } } } }

  .wrapper-resultado-resume {
    margin-top: 120px;
    .resume {
      margin-right: 160px;
      padding-left: 40px;
      overflow: hidden;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 1px;
        background: #d9dada;
        transform: translate3d(0, 100%, 0);
        transition: transform .8s $curva-lines-burger;
        will-change: transform; }
      h4.text-resume {
        font-family: $f-bold;
        margin-bottom: 45px;
        text-transform: uppercase;
        transform: translate3d(-15px, 0, 0);
        transition: transform 1.2s $curva-lines-burger, opacity 1.2s $curva-lines-burger;
        opacity: 0;
        will-change: transform, opacity; }
      span.firma-resume {
        display: block;
        font-family: $f-light;
        font-size: 15px;
        transform: translate3d(-15px, 0, 0);
        transition: transform 1.2s $curva-lines-burger .2s, opacity 1.2s $curva-lines-burger .2s;
        opacity: 0;
        will-change: transform, opacity; } }
    .resume.show {
      &::before {
        transform: translate3d(0, 0, 0); }
      h4.text-resume {
        opacity: 1;
        transform: translate3d(0, 0, 0); }
      span.firma-resume {
        opacity: 1;
        transform: translate3d(0, 0, 0); } } } }

section#creditos {
  .wrapper-creditos {
    ul.lista-creditos {
      li {
        margin-bottom: 20px; }
      li > :first-child {
        text-transform: uppercase;
        letter-spacing: 3px;
        font-family: $f-light;
        font-size: 19px; }
      li > :last-child {
        text-transform: capitalize;
        font-family: $f-light; } } } }


.Wonder {
  &__text {
    h4 {
      text-transform: uppercase;
      margin: 40px 0;
      letter-spacing: 1px;
      font-size: 18px; }
    p:nth-of-type(2) {
      margin-bottom: 40px; } }
  &__quote {
    display: flex;
    align-items: center;
    h3 {
      width: 70%;
      font-family: $f-bold;
      font-size: 33px; } } }

#wrapper-footer-proyectos {
  overflow: hidden;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: -1;
  .wrapper-siguiente {
    background: #d9dada;
    color: #808080;
    padding: 90px 0;
    .proyecto-next {
      position: relative;
      .texto-proyecto {
        span.sig {
          font-family: $f-medium;
          text-transform: uppercase;
          font-size: 12px;
          letter-spacing: 1px;
          display: block;
          margin-bottom: 10px; }
        h3.nombre-sig-proyecto {
          a {
            color: #808080;
            font-size: 38px;
            font-family: $f-bold;
            transition: color .5s ease;
            &:hover {
              color: #232323; } } } }
      .wrapper-btn-next {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translate3d(0, -50%, 0);
        a.button-next {
          display: block;
          width: 60px;
          height: 60px;
          .wrapper-outer-flecha {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover .circle {
              transform: translateX(-50%) scale(0.9);
              opacity: 0; }
            &:hover img.flecha {
              transform: translateX(10px) scale(1.1); }
            .circle {
              width: 100%;
              height: 100%;
              border: 1px solid #808080;
              border-radius: 50%;
              transition: transform .3s $curva-lines-burger, opacity .3s $curva-lines-burger;
              will-change: transform, opacity; }
            img.flecha {
              width: 45%;
              position: absolute;
              transition: transform .5s $curva-lines-burger;
              will-change: transform; } } } } } }

  .footer-pyectos {
    display: none;
    background: #F39C1E;
    padding: 30px 0; } }


