/* BREAK 991 */
@media screen and (max-width: 991.98px) {
  /* Grid-Works */
  .work-Name {
    transform: translateZ(40px);
    &__title {
      letter-spacing: 2px;
      font-size: 15px; } }

  .work-List {
    padding: 0 8px;
    &__item {
      font-family: $f-light;
      font-size: 13px; } }
  /* Fin Grid-Works */

  /* Home - La Agencia*/
  .agency-Home {
    &__fila-2 {
      margin-top: 50px; } }

  .agency-col-1 {
    padding-right: 15px;
    &__inner {
      width: 100%;
      margin-left: 0; } }
  /* Fin Home - La Agencia*/

  /* Home - Equipo*/
  .team-Home {
    &__fila-2 {
      margin-top: 50px; } }

  .team-text-1 {
    padding-right: 15px; }

  .we-are {
    transform: translate3d(-60px, -50%, 0);
    &__title {
      font-size: 50px; }
    &__text {
      display: inline; } }
  /* Fin Home - Equipo*/

  /* Project Inner */
  section#etapa-resultado {
    .wrapper-resultados-items {
      .resultado-item:nth-child(1) {
        margin-right: 0;
        padding: 0 45px; }
      .resultado-item:nth-child(2) {
        margin-left: 0;
        padding: 0 45px; } }

    .wrapper-resultado-resume {
      .resume {
        margin-right: 0;
        padding-left: 40px; } } }
  /* Fin Project Inner */ }


/* BREAK 770 */
@media screen and (max-width: 767.98px) {
  /* Home Principal */
  .title-principal {
    margin-bottom: 50px; }
  /* Fin Home Principal */

  /* Grid-Works */
  .works-Items {
    flex-wrap: wrap;
    &__column {
      width: 75%;
      margin-bottom: 35px; } }

  .work-Name {
    transform: translateZ(40px);
    &__title {
      font-size: 17px; } }

  .work-List {
    &__item {
      font-size: 15px; } }
  /* Fin Grid-Works */

  /* Reel */
  .Reel {
    padding: 50px 0; }
  /* Reel */

  /* Home - La Agencia*/
  .agency-Home {
    &__fila-2 {
      margin-top: 0; } }

  .agency-col-1 {
    order: 1;
    margin: 70px 0; }
  .agency-col-2 {
    order: 2; }
  /* Fin Home - La Agencia*/

  /* Home - Equipo*/
  .team-Home {
    &__fila-2 {
      margin-top: 50px; } }

  .team-text-1 {
    padding-right: 15px;
    margin-bottom: 70px; }

  .we-are {
    left: 35px;
    transform: translate3d(0, -50%, 0);
    &__title {
      font-size: 55px; }
    &__text {
      display: block; } }
  /* Fin Home - Equipo*/

  /* Contacto */
  .Footer__copy {
    color: #2f2f2f;
    font-size: 14px;
    text-align: center;
    &__copy {
      margin: 25px 0; }
    &__brand {
      text-align: inherit; } }
  /* Fin Contacto */

  /* Project Inner */
  section#etapa-resultado {
    .wrapper-resultados-items {
      .resultado-item:nth-child(1) {
        margin: 0;
        padding: 0 80px;
        margin-bottom: 75px; }
      .resultado-item:nth-child(2) {
        margin: 0;
        padding: 0 80px; }
      .resultado-item {
        article.inner-items:nth-child(1) {
          margin-bottom: 75px; }
        article.inner-items {
          margin: 0px 0; } } } }

  section#principal-project {
    #datos-proyecto {
      margin-bottom: 80px;
      article.datos-cliente {
        margin-bottom: 60px; } } }

  .wrapper-principal-etapa {
    margin-bottom: 75px;
    article.titulo-etapa {
      margin-bottom: 45px; } }

  #wrapper-footer-proyectos {
    .wrapper-siguiente {
      padding: 90px 0;
      .proyecto-next {
        .texto-proyecto {
          span.sig {}
          h3.nombre-sig-proyecto {
            a {
              font-size: 30px; } } } } } }
  /* Fin Project Inner */ }

/* BREAK 575 */
@media screen and (max-width: 575.98px) {

  /* Home Principal */
  .heroHome {
    &__video {
      display: none; } }

  .texts {
    &__title {
      font-size: 38px; }
    &__we {
      display: none; } }

  #video {
    display: none; }

  #slide {
    display: block; }

  .heroHome__slide {
    display: block; }

  #we-are {
    display: block;
    padding: 90px 0 0 0;
    p {
      color: $c-blanco;
      font-size: 23px; } }

  /* Fin Home Principal */

  /* Global */
  h2.g-title-section {
    font-size: 38px; }
  /* Fin Global */

  /* Header */
  .Header {
    &__container {
      padding: 0 0 0 15px; }
    &__container--height {
      a {
        h1 {
          width: 75px;
          height: 24px; } } } }

  .Lang {
    right: initial;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    &__item {
      height: 28px; }
    &__link {
      font-size: 17px;
      padding: 4px 8px; }
    &__line {
      height: 28px; } }

  .wrapper-burger {
    transform: translate3d(0, 2px, 0);
    padding: 15px; }
  /* Fin Header */

  /* Home titulos */
  .wrap-titles {
    margin-bottom: 20px; }
  /* Fin Home titulos */

  /* Home Projects */
  .works-Home {
    &__works-wrapper {
      margin-bottom: 25px; } }
  /* Fin Home Projects */

  /* Home - Equipo*/
  .we-are {
    left: 22px;
    &__title {
      font-size: 42px; } }
  /* Fin Home - Equipo*/


  /* Reel */
  .Reel {
    padding: 25px 0; }
  /* Reel */

  /* Contacto */
  .Footer {
    &__contact {
      padding: 75px 0 75px 0; } }


  .contact__info-wrapper {
    padding: 30px 0px;
    margin: 0 15px; }

  .contact__info-wrapper:nth-child(2) {
    border-bottom: 1px solid #212121;
    border-top: 1px solid #212121; }

  .contact {
    color: #898989;
    &__row {
      margin-top: 75px; }
    &__country {
      text-transform: uppercase;
      font-family: $f-medium;
      letter-spacing: 2px;
      font-size: 16px;
      margin-bottom: 40px; }
    &__location {
      display: block; }
    &__phone {
      margin: 20px 0; }
    &__imgMail {
      width: 26px;
      margin-bottom: 17px;
      margin-top: 20px;
      opacity: .3; } }
  /* Fin Contacto */

  /* Projects Page*/
  .Projects {
    padding: 115px 0 75px 0; }
  /* Fin Projects Page */

  /* Project Inner */
  .wrapper-principal-project {
    padding: 115px 0 75px 0; }

  .e-proyecto {
    padding: 100px 0; }

  section#etapa-resultado {
    .wrapper-resultados-items {
      .resultado-item:nth-child(1) {
        padding: 0 30px;
        margin-bottom: 60px; }
      .resultado-item:nth-child(2) {
        padding: 0 30px; }
      .resultado-item {
        article.inner-items:nth-child(1) {
          margin-bottom: 60px; } } }

    .wrapper-resultado-resume {
      .resume {
        padding-left: 15px;
        &::before {
          left: 15px; }
        h4.text-resume {
          padding-left: 25px;
          font-size: 22px;
          letter-spacing: 1px; }
        span.firma-resume {
          padding-left: 25px; } } } }

  section#principal-project {
    #datos-proyecto {
      article.datos-cliente {
        margin-bottom: 50px;
        h2.nombre-cliente {
          font-size: 35px; } } } }

  .wrapper-principal-etapa {
    margin-bottom: 60px;
    article.titulo-etapa {
      margin-bottom: 35px; } }

  #wrapper-footer-proyectos {
    position: relative;
    z-index: 1;
    .wrapper-siguiente {
      padding: 55px 0;
      .proyecto-next {
        .wrapper-btn-next {
          position: relative;
          right: auto;
          top: auto;
          transform: translate3d(0, 0, 0);
          left: 15px;
          width: 100%;
          margin-top: 30px;
          a.button-next {
            width: 40px;
            height: 40px; } } } } }
  /* Fin Project Inner */

  /* Hide-Menu */
  nav {
    width: 90vw;

    ul {
      li {
        margin: 7px 0;
        a {
          font-size: 35px; } } } }
  /* Fin Hide-Menu */ }


/* BREAK 500 */
@media screen and (max-width: 500px) {
  /* Grid-Works */
  .works-Items {
    &__column {
      width: 100%;
      margin-bottom: 35px; } }

  .work-Name {
    transform: translateZ(40px);
    &__title {
      font-size: 17px; } }

  .work-List {
    &__item {
      font-size: 15px; } }
  /* Fin Grid-Works */ }
