* {
  margin: 0;
  padding: 0; }

body, html {
  font-size: 16px;
  color: #000;
  width: 100%;
  font-family: $f-book;
  line-height: 1.3;
  background: #121212; }

body.no-scroll {
  overflow: hidden !important; }

h1, h2, h3, h4, h5, h6 {
  margin: 0; }

ul, ol {
  margin: 0; }

li {
  list-style: none; }

a {
  &:hover {
    text-decoration: none; } }

textarea {
  resize: none; }

input, textarea {
  &:focus {
    outline: 0; } }

::-webkit-input-placeholder {
  color: #989898; }

::-moz-selection {
  background: transparent;
  color: $c-naranja; }
::selection {
  background: transparent;
  color: $c-naranja; }

input:-webkit-autofill {
    -webkit-box-shadow: inset 0 0 0px 9999px #121212;
    -webkit-text-fill-color: $c-blanco !important; }
