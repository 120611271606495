.Header {
  position: fixed;
  width: 100%;
  z-index: 10;
  top: 0;
  transition: transform .3s $curva-header .1s, background .2s $curva-header;
  &__container {
    width: 100%;
    padding: 0 35px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  &__container--height {
    height: 53px;
    position: relative;
    a {
      display: inline-block;
      h1 {
        position: relative;
        display: block;
        text-indent: 100%;
        background: url('../img/global/logo-orange.svg');
        white-space: nowrap;
        overflow: hidden;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 93px;
        height: 30px; } } } }

.Lang {
  position: absolute;
  right: 180px;
  &__menu {
    display: flex;
    align-items: center; }
  &__item {
    height: 20px; }
  &__link {
    color: $c-blanco;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 14px;
    padding: 0 8px;
    transition: color .3s $curva-header;
    &:hover {
      color: #636363; } }
  &__line {
    width: 1px;
    height: 20px;
    background: #464646; } }


header#header.nav-up {
  transform: translate3d(0, -100%, 0); }

header#header.block-show {
  transform: translate3d(0, 0, 0); }


//////////////
.wrapper-burger {
  transform: translate3d(0, 2px, 0);
  .inner-wrapper {
    position: relative;
    height: 21px;
    width: 25px;
    cursor: pointer;
    background: none;
    .wrap-outer-line {
      width: 25px;
      height: 1px;
      display: block;
      position: absolute;
      transform: rotate(0deg);
      transition: .3s $curva-lines-burger;
      .medium-wrap-lines {
        transition: transform .3s $curva-lines-burger;
        transform: scale(1);
        transform-origin: right; }
      .medium-wrap-lines.open {
        /* transform: scale(1.2, 1) */ }
      .wrap-inner-lines {
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
        .line-same {
          width: 100%;
          height: 100%;
          display: block;
          position: absolute;
          transform-origin: 50% 50%;
          background-color: #fff;
          transform: translate3d(0, 0, 0);
          transition: transform .3s $curva-lines-burger; }
        .principal-line {}

        .hide-line {
          left: 100%; }

        .hide-extremes.open {
          transform: translate3d(-100%, 0, 0);
          transition-delay: .2s; }

        .hide-medium.open {
          transform: translate3d(-100%, 0, 0);
          transition-delay: .3s; }

        .principal-t.open {
          transform: translate3d(-100%, 0, 0); }
        .principal-t.leave {
          transition-delay: .1s; }

        .medium-p.open {
          transform: translate3d(-100%, 0, 0); }
        .medium-p.leave {
          transition-delay: .1s; } } } } }

.line-one {
  top: 0; }

.line-two {
  top: 7px; }

.line-three {
  top: 14px; }

.wrap-outer-line {
  transform-origin: center; }

.wrap-outer-line:nth-child(2) {
  transition: .3s ease-in-out; }

.wrap-outer-line.open:nth-child(1) {
  top: 8px;
  transform: rotate(135deg); }

.wrap-outer-line.open:nth-child(2) {
  opacity: 0;
  transform: translate3d(-100%, 0, 0); }

.wrap-outer-line.open:nth-child(3) {
  top: 8px;
  transform: rotate(-135deg); }
