/*Hero Home*/
.heroHome {
  height: 100vh;
  width: 100%;
  background: #000;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  transition: height 999999999s ease;
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 3;
    background: rgba(0,0,0,.4); }
  &__video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    min-width: 100%;
    min-height: 100vh;
    width: auto;
    height: auto;
    z-index: 2; }
  &__container {
    position: relative;
    z-index: 5;
    &__texts {
      color: $c-blanco; } } }

.texts {
  &__title {
    font-family: $f-bold;
    text-transform: capitalize;
    font-size: 45px;
    span.inner-text-p {
      position: relative;
      margin-bottom: 5px;
      display: block;
      opacity: 0;
      transform: translate3d(0, 20px, 0);
      transition: opacity 1s $curva-lines-burger, transform 1s $curva-lines-burger; } }
  &__we {
    font-family: $f-light; }
  &__we--fade {
    opacity: 0;
    transition: opacity 2s $curva-lines-burger .5s; }
  &__iso {
    max-width: 25px;
    margin-bottom: 17px; } }


.wrap-titles {
  position: relative;
  display: inline-block;
  margin-bottom: 30px;
  .t-home {
    opacity: 0;
    transform: translate3d(0, 20px, 0) skewY(2deg);
    transition: transform .8s $curva-lines-burger, opacity .8s $curva-lines-burger; } }

#we-are {
  display: none; }

/*Works Home*/
.works-Home {
  padding: 115px 0;
  overflow: hidden;
  &__works-wrapper {
    margin-bottom: 110px; } }


/* Reel */
.Reel {
  padding: 20px 0;
  &__video {
    padding: 56.25% 0 0;
    position: relative;
    height: 0;
    width: 100%;
    iframe {
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      position: absolute; } } }

/* Agency home */
.agency-Home {
  overflow: hidden; }

.heroHome__slide {
  display: none;
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100vh;
  transition: height 999999999s ease;
  .img-01 {
    background: url(../img/slide/slide-image-01.jpg); }
  .img-02 {
    background: url(../img/slide/slide-image-02.jpg); }
  .img-03 {
    background: url(../img/slide/slide-image-03.jpg); }
  .img-04 {
    background: url(../img/slide/slide-image-04.jpg); }
  .back-img {
    position: absolute;
    width: 100%;
    height: 100vh;
    opacity: 0;
    background-position: center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    transition: height 999999999s ease, opacity 1s ease; } }

