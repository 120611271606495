$f-light: FuturaPTLight;
$f-book: FuturaPTBook;
$f-medium: FuturaPTMedium;
$f-bold: FuturaPTBold;

$c-gris-claro: #d9dada;
$c-naranja: #F39C1B;
$c-blanco: #F1F1F1;

$curva-header: cubic-bezier(.84,.06,.21,.95);
$curva-lines-burger: cubic-bezier(.15,.95,.75,.94);
$curva-grid-work: cubic-bezier(0.3, 0.98, 0.52, 0.99);
$curva-hide-menu: cubic-bezier(.87,.83,.26,.95);
