.HeroForm {
  padding: 135px 0 100px 0;
  &__wrapperTitle {
    margin-bottom: 60px; }
  &__title {
    color: $c-blanco; } }

.Form {
  width: 100%;
  &__global-input {
    width: 100%;
    border: 0;
    background: #121212;
    padding: 15px 10px 15px 12px;
    border-bottom: 1px solid #3e3e3e;
    font-size: 16px;
    color: $c-blanco;
    text-transform: uppercase;
    margin-bottom: 25px; }
  &__textarea {
    height: 200px; }
  &__btn-send {
    background: #121212;
    cursor: pointer; } }

#fb-event {
  margin-top: 25px;
  display: inline-block; }

.wrapper-status {
  height: 35px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start; }
