/* Buttons */
.g-button {
  display: inline-block;
  padding: 12px 25px;
  position: relative;
  color: $c-blanco;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 2px;
  font-family: $f-light;
  overflow: hidden;
  z-index: 1;
  transition: border .7s $curva-lines-burger, color .7s cubic-bezier(.39,.23,.01,.97);
  &:before {
    content: '';
    position: absolute;
    width: 30px;
    height: 30px;
    left: var(--x);
    top: var(--y);
    background: radial-gradient(circle closest-side, #000000, transparent);
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: width .2s ease, height .2s ease, opacity .2s ease;
    z-index: -1; }
  &:hover:before {
    width: 95px;
    height: 95px;
    opacity: 1; } }
