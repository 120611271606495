/* Global */
.g-about {
  &__text {
    font-size: 17px;
    font-family: $f-book;
    color: #848484;
    line-height: 1.5;
    margin-bottom: 11px; } }

.g-about-sub {
  border-bottom: 1px solid #F39C1B; }

.g-about-title {
  text-transform: uppercase;
  font-family: $f-medium;
  color: #c5c5c5;
  font-size: 18px;
  letter-spacing: 3px;
  margin-bottom: 15px; }


/* Agency */
.agency-Home {
  padding: 100px 0 50px 0;
  &__fila-1 {
    margin-top: 50px; }
  &__fila-2 {
    margin-top: 80px; } }

.agency-col-1 {
  padding-right: 60px;
  display: flex;
  align-items: center;
  &__inner {
    width: 75%;
    margin-left: 50px; } }


/* Team */
.team-Home {
  padding: 100px 0 150px 0;
  overflow: hidden;
  &__fila-1 {
    margin-top: 50px; }
  &__fila-2 {
    margin-top: 80px;
    position: relative; } }

.team-text-1 {
  padding-right: 80px; }

.we-are {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-120px, -50%, 0);
  &__title {
    color: $c-blanco;
    font-size: 74px;
    font-family: $f-bold;
    letter-spacing: 1px;
    position: relative;
    text-shadow: 5px 5px 18px rgba(0,0,0,.5); }
  &_inner--3d {
    transform-style: preserve-3d; }
  &__inner {
    position: relative;
    will-change: transform;
    transform: perspective(1000px) rotateY(0deg) rotateX(0deg); }
  &__text {
    display: block; } }

span.sub-orange {
  display: inline-block;
  position: relative;
  z-index: 1; }
