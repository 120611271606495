@font-face {
  font-family: FuturaPTLight;
  src: url(../fuentes/FuturaPTLight.otf) format("truetype"); }

@font-face {
  font-family: FuturaPTBook;
  src: url(../fuentes/FuturaPTBook.otf) format("truetype"); }

@font-face {
  font-family: FuturaPTMedium;
  src: url(../fuentes/FuturaPTMedium.otf) format("truetype"); }

@font-face {
  font-family: FuturaPTBold;
  src: url(../fuentes/FuturaPTBold.otf) format("truetype"); }

