//Linea carga
.linea-carga {
  width: 100%;
  max-width: 1110px;
  position: absolute;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  top: 0;
  height: 3px;
  overflow: hidden;
  &::after {
    content: '';
    position: absolute;
    height: 3px;
    width: 100%;
    opacity: 1;
    transform-origin: top;
    transform: translate3d(-100%, 0, 0);
    transition: transform .8s $curva-hide-menu; } }

.linea-carga.show {
  &::after {
    transform: translate3d(0, 0, 0); } }

.linea-celeste {
  &::after {
    background: #689ad2; } }

.linea-verde {
  &::after {
    background: #3cbcac; } }

.linea-rosa {
  &::after {
    background: #f27a7e; } }

.linea-amarillo {
  &::after {
    background: #f39c1e; } }
//Fin Linea carga

//Colores back
.wrapper-comb-1 {
  background: #d9dada;
  color: #494747; }

.wrapper-comb-2 {
  background: #bbbcbb;
  color: #d9dada; }

.wrapper-comb-3 {
  background: #3a3a3a;
  color: #bbbcbb; }

.wrapper-comb-4 {
  background: #232323;
  color: #d9dada; }

.wrapper-comb-5 {
  background: #565656;
  color: #d9dada; }
//Fin Colores back
