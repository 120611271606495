#preload {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  background: #000000;
  visibility: visible;
  opacity: 1;
  transition: opacity .9s ease, visibility .9s ease; }

#preload.open {
  opacity: 0;
  visibility: hidden; }

.Preload {
  display: flex;
  justify-content: center;
  align-items: center;
  &__inner {
    text-align: center; }
  &__image {
    width: 150px; } }


.orange-style {
  border: 1px solid $c-naranja;
  &:hover {
    color: $c-naranja;
    border: 1px solid #4e2f00; } }

.standar-style {
  border: 1px solid $c-blanco;
  &:hover {
    color: #9c9c9c;
    border: 1px solid #1b1b1b; } }


.text-status {
  color: $c-blanco; }


.text-status.success {
	color: $c-blanco; }

.text-status.error {
	color: $c-blanco; }


.t-bold {
  font-family: $f-bold; }

.t-medium {
  font-family: $f-medium; }

.block-center {
  text-align: center; }

/*Titulos Sections*/
h2.g-title-section {
  font-size: 45px;
  color: $c-gris-claro;
  text-transform: capitalize; }

