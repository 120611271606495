#hide-menu {
  background: #000;
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 9;
  transition-timing-function: $curva-hide-menu;
  transition: opacity .6s .7s, visibility .6s .7s, height 999999999s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden; }

#hide-menu.open {
  opacity: 1;
  visibility: visible;
  transition-timing-function: $curva-hide-menu;
  transition: opacity .6s, visibility .6s; }


nav {
  width: 80vw;
  text-align: center;
  position: relative;
  padding: 20px 0;
  z-index: 5;
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background: #333333;
    bottom: 0;
    left: 0;
    opacity: 0;
    transform: translate3d(0, -100px, 0);
    transition-timing-function: $curva-hide-menu;
    transition: transform .6s .4s, opacity .6s ease .4s; }

  ul {
    li {
      display: inline-block;
      margin: 0 10px;
      a {
        display: inline-block;
        color: #fff;
        font-size: 45px;
        text-transform: uppercase;
        font-family: $f-book;
        letter-spacing: 3px;
        opacity: 0;
        transform: translate3d(0, -40px, 0);
        transition-timing-function: $curva-hide-menu;
        transition: transform .4s, opacity .4s, color .5s;
        &:hover {
          color: $c-naranja; } }
      a.is-showing {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition-timing-function: $curva-hide-menu;
        transition: transform .8s .4s, opacity .8s .4s, color .5s; } } } }

nav.show {
  &::after {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition-timing-function: $curva-hide-menu;
    transition: transform 1s .5s, opacity 2.5s .5s; } }

.foto {
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  position: absolute;
  width: 400px; }

.inner {
  width: 100%;
  height: 100%;
  position: relative; }

.shape {
  width: 100%;
  height: 100%;
  display: block;
  opacity: 0;
  transform: translate3d(0, 50px, 0);
  transition: opacity .5s $curva-hide-menu, transform .5s $curva-hide-menu; }

.shape.show {
  opacity: 1;
  transform: translate3d(0, 0, 0); }


#email-contact {
  position: absolute;
  left: 50%;
  bottom: 50px;
  transform: translate3d(-50%, 0, 0);
  opacity: 0;
  transition: opacity .8s ease .3s;
  span {
    color: #fff;
    letter-spacing: 1px;
    font-family: $f-light; } }

#email-contact.show {
  opacity: 1;
  transition: opacity .8s ease 1s; }
