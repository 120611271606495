.works-Items {
  width: 100%;
  margin-top: 50px;
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  transform-style: preserve-3d;
  &::after {
    display: table;
    clear: both;
    content: " "; }
  &__column {
    width: 33.333333%; }
  &__column--anim {
    position: relative;
    transform-origin: center;
    transform: translate3d(0, 45px, 0) scale3d(0.9, 0.9, 0.9) rotateX(25deg) rotateY(20deg) skew(3deg) perspective(800px);
    opacity: 0;
    transition: opacity .7s $curva-grid-work, transform .7s $curva-grid-work; }
  &__column--fix {
    overflow: hidden;
    position: relative;
    &::before {
      margin-top: 100%;
      content: "";
      display: block;
      box-sizing: border-box; } } }


.work-uno {
  background: url('../img/grid-works/card-juegos.gif'); }
.work-dos {
  background: url('../img/grid-works/card-mccarthys-sports.gif'); }
.work-tres {
  background: url('../img/grid-works/card-bioesthetics.gif'); }
.work-cuatro {
  background: url('../img/grid-works/card-laliga.gif'); }
.work-cinco {
  background: url('../img/grid-works/card-mccarthys-pub.gif'); }
.work-seis {
  background: url('../img/grid-works/card-clever.gif'); }
.work-siete {
  background: url('../img/grid-works/card-wonder-woman.jpg'); }
.work-ocho {
  background: url('../img/grid-works/card-wavenet.gif'); }

.item-Content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0;
  min-height: 1px;
  box-sizing: border-box;
  &:hover .work-List .work-List__item {
    opacity: 1;
    visibility: visible;
    transform: matrix(1, 0, 0, 1, 0, 0); }
  &__dis {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    transform-style: preserve-3d;
    width: 100%;
    height: 100%; }
  &__back {
    opacity: 1;
    will-change: transform;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    transform: perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1); } }

.work-Name {
  transform: translateZ(40px);
  &__title {
    color: #fff;
    font-weight: bold;
    letter-spacing: 3px;
    font-size: 18px;
    text-shadow: 0 0 16px rgba(0,0,0,.6);
    text-transform: uppercase; } }

.work-List {
  position: absolute;
  bottom: 30px;
  width: 100%;
  padding: 0 10px;
  transform: translateZ(20px);
  &__item {
    display: inline-block;
    color: #fff;
    text-transform: uppercase;
    margin: 0 5px;
    font-size: 15px;
    letter-spacing: 1px;
    opacity: 0;
    visibility: hidden;
    transform: matrix(1, 0, 0, 1, 0, 15);
    will-change: transform, opacity;
    transition: transform .3s $curva-grid-work, opacity .3s $curva-grid-work, visibility .3s $curva-grid-work; }
  &__item:nth-child(1) {
    transition-delay: 0s; }
  &__item:nth-child(2) {
    transition-delay: .1s; }
  &__item:nth-child(3) {
    transition-delay: .2s; } }

.glare {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  &__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    pointer-events: none;
    background-image: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
    width: 758px;
    height: 758px;
    transform: rotate(180deg) translate(-50%, -50%);
    transform-origin: 0% 0% 0px;
    opacity: 0;
    transition: opacity .3s $curva-grid-work;
    will-change: transform, opacity; } }
